<template>
  <div class="additional-add-edit-modal">
    <bg-modal
      :value="showModal"
      :title="modalTitle"
      description="Masukkan informasi biaya & layanan tambahan kos."
      :close-on-click-backdrop="false"
      desktop-size="md"
      @close="handleModal"
    >
      <div class="additional-add-edit-modal__content">
        <bg-field label="Jenis Biaya">
          <bg-select
            v-model="additionalFees.type"
            placeholder="Jenis Biaya"
            @change="handleShowPriceField"
            :options="feeNameOptions"
            :disabled="!isAdd"
            data-testid="additional-fee-type"
          />
        </bg-field>
        <template>
          <div v-if="additionalFees.type === 'dp'">
            <bg-field label="Harga (Persentase)">
              <bg-select
                v-model="additionalFees.amount"
                :options="dpOptions"
                data-testid="additional-fee-amount-dp"
              />
            </bg-field>
          </div>
          <div v-if="additionalFees.type === 'deposit'">
            <input-currency-masking
              class="additional-add-edit-modal__input"
              field-label="Harga"
              prefix="Rp"
              v-model="additionalFees.amount"
              max-char="12"
              data-testid="additional-fee-amount-deposit"
            />
          </div>
          <div v-if="additionalFees.type === 'additional'">
            <bg-field label="Nama Layanan">
              <bg-input
                v-model="additionalFees.name"
                data-testid="additional-fee-service-name"
              />
            </bg-field>
            <input-currency-masking
              class="additional-add-edit-modal__input"
              field-label="Besar Biaya"
              prefix="Rp"
              v-model="additionalFees.amount"
              max-char="12"
              data-testid="additional-fee-amount-additional"
            />
          </div>
          <div v-if="additionalFees.type === 'fine'">
            <input-currency-masking
              class="additional-add-edit-modal__input"
              field-label="Besar Biaya"
              prefix="Rp"
              message="Contoh: 7 Hari"
              v-model="additionalFees.amount"
              max-char="12"
              data-testid="additional-fee-amount-fine"
            />
            <bg-field
              label="Dibebankan jika penyewa terlambat bayar lewat dari"
            >
              <div class="additional-add-edit-modal__flex">
                <bg-input v-model="additionalFees.duration" />
                <bg-select
                  v-model="additionalFees.duration_unit"
                  :options="durationOptions"
                  data-testid="additional-fee-duration-unit"
                />
              </div>
            </bg-field>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="additional-add-edit-modal__footer">
          <bg-button
            variant="primary"
            @click="handleSaveAdditionalFee"
            :loading="loadingButton"
            :disabled="!additionalFees.type || !additionalFees.amount"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { BgModal, BgButton, BgInput, BgField, BgSelect } from 'bangul-vue';
import InputCurrencyMasking from '@admin/components/molecules/InputCurrencyMasking';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';

export default {
  name: 'AdditionalDeleteModal',

  components: {
    BgModal,
    BgInput,
    BgButton,
    BgField,
    BgSelect,
    InputCurrencyMasking,
  },

  data() {
    return {
      isShowPriceField: false,
      loadingButton: false,
      selectDp: '',
      additionalFees: {},
      feeNameOptions: [
        {
          val: 'dp',
          label: 'Uang Muka (DP)',
        },
        {
          val: 'deposit',
          label: 'Biaya Deposit',
        },
        {
          val: 'fine',
          label: 'Biaya Denda',
        },
        {
          val: 'additional',
          label: 'Biaya Lainnya',
        },
      ],
      dpOptions: [
        {
          val: 0,
          label: '0%',
        },
        {
          val: 10,
          label: '10%',
        },
        {
          val: 20,
          label: '20%',
        },
        {
          val: 30,
          label: '30%',
        },
        {
          val: 40,
          label: '40%',
        },
        {
          val: 50,
          label: '50%',
        },
      ],
      durationOptions: [
        {
          val: 'day',
          label: 'Hari',
        },
        {
          val: 'week',
          label: 'Minggu',
        },
        {
          val: 'month',
          label: 'Bulan',
        },
      ],
      propertyListingData: {},
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'add',
    },
    editData: {
      type: Object,
      default: () => {},
    },
    listingId: {
      type: [String, Number],
      default: '',
    },
    propertyId: {
      type: [String, Number],
      default: '',
    },
  },

  computed: {
    modalTitle() {
      return `${this.isAdd ? 'Tambah' : 'Ubah'} Biaya & Layanan`;
    },
    isAdd() {
      return !!(this.modalType === 'add');
    },
    feeId() {
      return this.editData?.id;
    },
  },

  watch: {
    editData() {
      if (!this.isAdd) {
        this.additionalFees = this.$props.editData;
      } else {
        this.additionalFees = {};
      }
    },
    showModal(isOpen) {
      this.isShowModal = isOpen;
      if (!isOpen) {
        this.additionalFees = {};
      }
    },
  },

  methods: {
    handleModal() {
      this.$emit('on-close-add-edit-modal', this.isAdd);
    },

    handleShowPriceField() {
      this.isShowPriceField = true;
    },

    handleSaveAdditionalFee() {
      const amountValue = Number(
        this.additionalFees.amount.toString().replace(/\D/g, '')
      );
      const amountType =
        this.additionalFees.type === 'dp' ? 'percentage' : 'nominal';
      let feeName;
      if (this.additionalFees.type === 'deposit') {
        feeName = 'Deposit';
      } else if (this.additionalFees.type === 'dp') {
        feeName = `DP ${this.additionalFees.amount}%`;
      } else if (this.additionalFees.type === 'fine') {
        feeName = 'Denda';
      } else feeName = this.additionalFees.name;

      this.additionalFees = {
        ...this.additionalFees,
        amount: amountValue,
        type: this.additionalFees.type,
        amount_type: amountType,
        is_active: true,
        name: feeName,
        period: this.additionalFees.duration,
        period_unit: this.additionalFees.duration_unit,
      };

      if (this.isAdd) {
        this.addAdditionalFee();
      } else {
        this.editAdditionalFee();
      }
    },

    async addAdditionalFee() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.postAdditionalFee({
          propertyId: this.propertyId,
          listingId: this.listingId,
          data: this.additionalFees,
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }

      if (!result.hasError) {
        this.$toast.show('Biaya dan layanan berhasil ditambah.');
        this.handleModal();
        window.location.reload();
      }
      this.loadingButton = false;

      return result;
    },

    async editAdditionalFee() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.updateAdditionalFee({
          propertyId: this.propertyId,
          listingId: this.listingId,
          feeId: this.feeId,
          data: this.additionalFees,
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }

      if (!result.hasError) {
        this.$toast.show('Perubahan berhasil disimpan');
        this.handleModal();
        window.location.reload();
      }
      this.loadingButton = false;

      return result;
    },
  },
};
</script>

<style lang="scss" scoped src="./AdditionalAddEditModal.scss"></style>

<template>
  <div class="form-property-listing-info">
    <bg-skeleton
      v-if="loadingFetchData && loadingFetchOptions"
      width="40%"
      :height="40"
      data-testid="form-property-listing-info-loader"
    />
    <template v-else>
      <div class="form-property-listing-info__header-section">
        <bg-text size="heading-2">
          {{ selectedRoomTypeForUpdate || xKosListingData.name }}
        </bg-text>
      </div>
      <div class="form-property-listing-info__form-section">
        <bg-text size="heading-4">Informasi Kamar</bg-text>
        <bg-grid class="form-property-listing-info__form-row">
          <bg-grid-item :col="3">
            <bg-field label="List ID">
              <bg-input v-model="xKosListingData.id" disabled />
            </bg-field>
          </bg-grid-item>
          <bg-grid-item :col="3">
            <bg-field label="Jenis Kamar">
              <bg-select
                v-model="xKosListingData.gender"
                :options="genderTypeOptions"
                data-testid="form-gender-dropdown"
                @input="updatePropertyGender"
              />
            </bg-field>
          </bg-grid-item>
          <bg-grid-item :col="3">
            <bg-field label="Total Kamar">
              <bg-input v-model="xKosListingData.total_unit" disabled />
            </bg-field>
          </bg-grid-item>
          <bg-grid-item :col="3">
            <bg-field label="Kamar Kosong">
              <bg-input v-model="xKosListingData.available_unit" disabled />
            </bg-field>
          </bg-grid-item>
          <bg-grid-item>
            <bg-field label="Iklan di Mamikos">
              <bg-input v-model="xKosListingData.page_url" disabled />
            </bg-field>
          </bg-grid-item>
        </bg-grid>
      </div>
      <div class="form-property-listing-info__table-section">
        <div class="form-property-listing-info__table-subtitle">
          <bg-text size="heading-4">Spesifikasi Kamar</bg-text>
          <bg-button
            variant="primary"
            data-testid="form-property-listing-info-add-button"
            @click="handleShowAddEditModal(true)"
            >+ Tambah Kamar</bg-button
          >
        </div>
        <div class="form-property-listing-info__table-container">
          <table
            class="form-property-listing-info__table ss-table"
            data-testid="form-property-listing-info-table"
          >
            <thead>
              <tr class="form-property-listing-info__table-head">
                <th class="form-property-listing-info__table-col-sm">
                  Nomor Kamar
                </th>
                <th class="form-property-listing-info__table-col-xs">Lantai</th>
                <th class="form-property-listing-info__table-col-sm">
                  Ukuran Kamar
                </th>
                <th class="form-property-listing-info__table-col-md">
                  Letak Jendela
                </th>
                <th class="form-property-listing-info__table-col-md">
                  Tipe Kasur
                </th>
                <th class="form-property-listing-info__table-col-md">
                  Jenis Listrik
                </th>
                <th class="form-property-listing-info__table-col-sm">
                  Daya Listrik
                </th>
                <th class="form-property-listing-info__table-col-md">
                  Deskripsi Kamar
                </th>
                <th
                  class="form-property-listing-info__table-col-sm form-property-listing-info__table-col--center"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(unit, i) in paginationUnitData" :key="i">
                <td>{{ unit.name }}</td>
                <td>{{ unit.floor }}</td>
                <td>{{ unit.room_size ? unit.room_size + ' meter' : '-' }}</td>
                <td>{{ unit.window_location || '-' }}</td>
                <td>{{ unit.roomBedInfo || '-' }}</td>
                <td>{{ unit.electricity_type || '-' }}</td>
                <td>
                  {{
                    unit.electricity_power
                      ? unit.electricity_power + ' VA'
                      : '-'
                  }}
                </td>
                <td>{{ unit.description || '-' }}</td>
                <td class="form-property-listing-info__table-col-sm">
                  <div class="flex justify-center">
                    <bg-dropdown
                      menu-placement="bottom-end"
                      menu-size="fit-to-content"
                      role="menu"
                    >
                      <template #trigger>
                        <bg-icon
                          name="more-vertical"
                          class="form-property-listing-info__table-action-button"
                        />
                      </template>

                      <bg-dropdown-item
                        class="form-property-listing-info__table-action-item"
                        v-for="(action, i) in actions"
                        role="menuitem"
                        :key="i"
                        @click="action.actionClick(unit)"
                      >
                        {{ action.title }}
                      </bg-dropdown-item>
                    </bg-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-property-listing-info__footer-section">
        <bg-pagination
          v-if="xKosListingData.units.length > pagination.limit"
          v-model="pagination.page"
          :page-total="pagination.total"
          @click="handlePagination"
        />
      </div>
      <unit-add-edit-modal
        :show-modal="isShowAddEditModal"
        :modal-type="addEditModalType"
        :edit-data="editData"
        :general-facilities="xKosListingData.general_facilities"
        :property-id="propertyId"
        :listing-id="listingId"
        :unit-id="unitId"
        :options="xKosListingFormOptions"
        data-testid="unit-add-edit-modal"
        @on-close-add-edit-modal="handleShowAddEditModal"
      />
      <unit-delete-modal
        :show-modal="isShowDeleteModal"
        :property-id="propertyId"
        :listing-id="listingId"
        :unit-id="unitId"
        data-testid="unit-delete-modal"
        @on-close-delete-modal="isShowDeleteModal = false"
        @on-failure-delete="handleFailureDeleteUnit"
      />

      <!-- Deletion Failure Modal -->
      <bg-modal
        title="Kamar tidak dapat dihapus"
        :value="isShowDeletionFailureModal"
        :description="failureModalText"
        :close-on-click-backdrop="false"
        desktop-size="sm"
        button-second-text="Kembali"
        @click-second-action="isShowDeletionFailureModal = false"
        @close="isShowDeletionFailureModal = false"
      />
    </template>
  </div>
</template>

<script>
import {
  BgText,
  BgGrid,
  BgGridItem,
  BgInput,
  BgField,
  BgButton,
  BgIcon,
  BgPagination,
  BgSelect,
  BgSkeleton,
  BgDropdown,
  BgDropdownItem,
  BgModal,
} from 'bangul-vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';
import UnitAddEditModal from '@admin/pages/FormPropertyListingInfo/components/UnitAddEditModal';
import UnitDeleteModal from '@admin/pages/FormPropertyListingInfo/components/UnitDeleteModal';

export default {
  name: 'FormPropertyListingInfo',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgInput,
    BgButton,
    BgField,
    BgIcon,
    BgPagination,
    BgSelect,
    BgSkeleton,
    UnitAddEditModal,
    UnitDeleteModal,
    BgDropdown,
    BgDropdownItem,
    BgModal,
  },

  computed: {
    ...mapState('propertyDetail', [
      'xLoadingStateListing',
      'xKosListingData',
      'xLoadingStateListingFormOptions',
      'xKosListingFormOptions',
    ]),
    loadingFetchData() {
      return this.xLoadingStateListing === LOADING_STATE.FETCHING;
    },
    loadingFetchOptions() {
      return this.xLoadingStateListingFormOptions === LOADING_STATE.FETCHING;
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    listingId() {
      return this.$route.params.listingId;
    },
    actions() {
      return [
        {
          title: 'Ubah',
          actionClick: rowId => {
            this.handleShowAddEditModal(false, rowId);
          },
        },
        {
          title: 'Hapus',
          actionClick: unit => {
            this.handleShowDeleteModal(unit.id);
          },
        },
      ];
    },

    selectedRoomTypeForUpdate() {
      return localStorage.getItem('selectedRoomTypeToUpdate');
    },
  },

  async created() {
    await Promise.allSettled([
      this.xConsumeKosListing({
        propertyId: this.propertyId,
        listingId: this.listingId,
      }),

      this.xConsumeListingFormOptions({
        propertyId: this.propertyId,
        listingId: this.listingId,
      }),
    ]);
  },

  data() {
    return {
      genderTypeOptions: [
        {
          val: 'campur',
          label: 'Campur',
        },
        {
          val: 'putri',
          label: 'Putri',
        },
        {
          val: 'putra',
          label: 'Putra',
        },
      ],
      isShowAddEditModal: false,
      addEditModalType: 'add',
      editData: null,
      isShowDeleteModal: false,
      loadingButton: false,
      pagination: {
        page: 1,
        total: 1,
        limit: 10,
      },
      unitId: 0,
      paginationUnitData: [],
      isShowDeletionFailureModal: false,
      failureModalText: '',
    };
  },

  watch: {
    xLoadingStateListing: {
      immediate: true,
      handler(value) {
        if (value === LOADING_STATE.FETCH_DONE) {
          const dataLength = this.xKosListingData?.units?.length || 0;
          this.pagination.total = Math.ceil(dataLength / this.pagination.limit);
          this.handlePagination(this.pagination.page);
          this.handleBreadcrumb();
        }
      },
    },
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),
    ...mapActions('propertyDetail', [
      'xConsumeKosListing',
      'xConsumeListingFormOptions',
    ]),

    handleBreadcrumb() {
      let breadcrumbLists;

      if (this.selectedRoomTypeForUpdate) {
        breadcrumbLists = [
          {
            name: this.xKosListingData.name,
            url: `/property-detail/${this.propertyId}/kos`,
          },
          {
            name: this.selectedRoomTypeForUpdate,
            url: `/property-detail/${this.propertyId}/kos`,
          },
          {
            name: 'Informasi Kamar',
            url: '',
          },
        ];
      } else {
        breadcrumbLists = [
          {
            name: this.xKosListingData.name,
            url: `/property-detail/${this.propertyId}/kos`,
          },
          {
            name: 'Informasi Kamar',
            url: '',
          },
        ];
      }

      breadcrumbLists.forEach((list, i) => {
        this.updateBreadcrumb({
          index: i + 1,
          item: {
            name: list.name,
            url: list.url,
          },
        });
      });
    },

    async updatePropertyGender() {
      this.loadingFetchData = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.updateRoomUnitGender({
          propertyId: this.propertyId,
          listingId: this.listingId,
          data: {
            gender: this.xKosListingData.gender,
          },
        });
        this.$toast.show('Perubahan berhasil disimpan');
        window.location.reload();
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }
      this.loadingFetchData = false;
      return result;
    },

    handleShowAddEditModal(isAdd, data) {
      this.isShowAddEditModal = !this.isShowAddEditModal;

      if (this.isShowAddEditModal) {
        if (isAdd) {
          this.addEditModalType = 'add';
          this.editData = null;
        } else {
          this.addEditModalType = 'edit';
          this.editData = {
            ...data,
          };
          this.unitId = data.id;
        }
      }
    },

    handleShowDeleteModal(id) {
      this.isShowDeleteModal = true;
      this.unitId = id;
    },

     handleFailureDeleteUnit({ text }) {
      this.failureModalText = text;

      this.isShowDeletionFailureModal = true;
      this.isShowDeleteModal = false;
    },

    handlePagination(page) {
      const { units } = this.xKosListingData;
      const pageArray = units.slice(
        (page - 1) * this.pagination.limit,
        page * this.pagination.limit
      );
      this.paginationUnitData = pageArray;
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyListingInfo.scss"></style>

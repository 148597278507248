import api from '@admin/api/service';

export default {
  putUpdateNormalRoomPrice(params) {
    const { propertyId, listingId, data } = params;
    return api.put(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/price`,
      data
    );
  },

  postRoomUnitListing(params) {
    const { propertyId, listingId, data } = params;
    return api.post(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/unit`,
      data
    );
  },

  deleteRoomUnitListing(params) {
    const { propertyId, listingId, unitId, reason } = params;

    return api.post(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/unit/${unitId}/delete-with-reason`, { reason }
    );
  },

  editRoomUnitListing(params) {
    const { propertyId, listingId, unitId, data } = params;
    return api.put(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/unit/${unitId}`, data
    );
  },

  updateRoomUnitGender(params) {
    const { propertyId, listingId, data } = params;
    return api.put(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/info`, data
    );
  },

  postAdditionalFee(params) {
    const { propertyId, listingId, data } = params;
    return api.post(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/additional-fee`,
      data
    );
  },

  deleteAdditionalFee(params) {
    const { propertyId, listingId, feeId } = params;
    return api.delete(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/additional-fee/${feeId}`
    );
  },

  updateAdditionalFee(params) {
    const { propertyId, listingId, feeId, data } = params;
    return api.put(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/additional-fee/${feeId}`, data
    );
  },

  updatePropertyInformation(params) {
    const { propertyId, data } = params;
    return api.put(
      `/singgahsini/api/property/${propertyId}/info`, data
    );
  },

  updateStrategicLocation(params) {
    const { propertyId, data } = params;
    return api.put(
      `/singgahsini/api/property/${propertyId}/info/strategic-location`, data
    );
  },

  updatePropertyFacility(params) {
    const { propertyId, listingId, data } = params
    return api.put(
      `singgahsini/api/property/${propertyId}/listing/${listingId}/facility`, data
    );
  },

  createBulkPropertyKeeper(params) {
    const { propertyId, data } = params;
    return api.post(
      `singgahsini/api/property/${propertyId}/keeper/bulk`, data
    );
  },

  getPropertyTagList(type) {
    return api.get(
      `singgahsini/api/tag?type=${type}`
    );
  },
};

<template>
  <div class="unit-add-edit-modal">
    <bg-modal
      :value="showModal"
      :title="modalTitle"
      :description="modalDescription"
      :close-on-click-backdrop="false"
      desktop-size="md"
      @close="handleShowModal"
    >
      <div class="unit-add-edit-modal__content">
        <bg-grid>
          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Nomor Kamar">
              <bg-input
                v-model="unitData.name"
                data-testid="unit-name"
                @keyup="handleField('name')"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Lantai Kamar">
              <bg-input
                class="unit-add-edit-modal__subfield--number"
                v-model="unitData.floor"
                type="number"
                data-testid="unit-floor"
                @keyup="handleField('floor')"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-text size="title-5" class="mb-4 bg-u-text-neutral-900"
              >Ukuran Kamar</bg-text
            >
            <bg-grid>
              <bg-grid-item
                :col="6"
                class="mb-0 unit-add-edit-modal__subfield--left"
              >
                <bg-field label="Panjang">
                  <bg-input
                    disabled
                    v-model="unitData.room_length"
                    suffix="meter"
                    data-testid="unit-room-length"
                  />
                </bg-field>
              </bg-grid-item>

              <bg-grid-item
                :col="6"
                class="mb-0 unit-add-edit-modal__subfield--right"
              >
                <bg-field label="Lebar">
                  <bg-input
                    disabled
                    v-model="unitData.room_width"
                    suffix="meter"
                    data-testid="unit-room-width"
                  />
                </bg-field>
              </bg-grid-item>
            </bg-grid>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Letak Jendela">
              <bg-select
                v-model="unitData.window_location"
                :options="windowLocations"
                placeholder=" "
                data-testid="unit-window-location"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Tipe Kasur">
              <bg-select
                v-model="unitData.bed_type"
                :options="bedTypes"
                placeholder=" "
                data-testid="unit-bed-type"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-text size="title-5" class="mb-4 bg-u-text-neutral-900"
              >Ukuran Kasur</bg-text
            >
            <bg-grid>
              <bg-grid-item
                :col="6"
                class="mb-0 unit-add-edit-modal__subfield--left"
              >
                <bg-field label="Panjang">
                  <bg-input
                    class="unit-add-edit-modal__subfield--number"
                    v-model="unitData.bed_length"
                    suffix="cm"
                    type="number"
                    data-testid="unit-bed-length"
                    @keyup="handleField('bed_length')"
                  />
                </bg-field>
              </bg-grid-item>

              <bg-grid-item
                :col="6"
                class="mb-0 unit-add-edit-modal__subfield--right"
              >
                <bg-field label="Lebar">
                  <bg-input
                    class="unit-add-edit-modal__subfield--number"
                    v-model="unitData.bed_width"
                    suffix="cm"
                    type="number"
                    data-testid="unit-bed-width"
                    @keyup="handleField('bed_width')"
                  />
                </bg-field>
              </bg-grid-item>
            </bg-grid>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Jenis Listrik">
              <bg-select
                v-model="unitData.electricity_type"
                :options="electricityTypes"
                placeholder=" "
                data-testid="unit-electricity-type"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="6" class="mb-0">
            <bg-field label="Daya Listrik">
              <bg-input
                class="unit-add-edit-modal__subfield--number"
                v-model="unitData.electricity_power"
                data-testid="unit-electricity-power"
                suffix="VA"
                type="number"
                @keyup="handleField('electricity_power')"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="12" class="mb-0">
            <bg-field
              label="Deskripsi Kamar"
              message="Contoh: Tidak ada balkon / kamar terletak di ujung koridor"
            >
              <bg-input
                v-model="unitData.description"
                maxlength="255"
                data-testid="unit-description"
              />
            </bg-field>
          </bg-grid-item>
        </bg-grid>
      </div>
      <div class="unit-add-edit-modal__footer">
        <bg-button
          variant="primary"
          :loading="loadingButton"
          :disabled="isSubmitButtonDisabled"
          @click="handleSaveButton"
          >Simpan</bg-button
        >
      </div>
    </bg-modal>
  </div>
</template>

<script>
import {
  BgModal,
  BgButton,
  BgInput,
  BgField,
  BgGrid,
  BgGridItem,
  BgSelect,
  BgText,
} from 'bangul-vue';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';

const mapOptions = options =>
  options.map(option => ({ val: option, label: option }));

export default {
  name: 'UnitAddEditModal',

  components: {
    BgModal,
    BgInput,
    BgButton,
    BgField,
    BgGrid,
    BgGridItem,
    BgSelect,
    BgText,
  },

  data() {
    return {
      loadingButton: false,
      unitData: {
        floor: '',
        name: '',
        room_length: '',
        room_width: '',
        window_location: '',
        bed_type: '',
        bed_length: '',
        bed_width: '',
        electricity_type: '',
        electricity_power: '',
        description: '',
      },
      errorState: {
        floor: {
          value: false,
          message: '',
        },
        name: {
          value: false,
          message: '',
        },
      },
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'add',
    },
    editData: {
      type: Object,
      default: () => {},
    },
    generalFacilities: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
    propertyId: {
      type: [String, Number],
      default: '',
    },
    listingId: {
      type: [String, Number],
      default: '',
    },
    unitId: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
  },

  computed: {
    modalTitle() {
      return this.modalType === 'add' ? 'Tambah Kamar' : 'Ubah Kamar';
    },
    modalDescription() {
      return this.modalType === 'add'
        ? 'Masukkan informasi lantai, nomor, dan deskripsi kamar yang akan ditambahkan.'
        : '';
    },
    isSubmitButtonDisabled() {
      const excludedProperties = ['description', 'room_length', 'room_width'];

      const hasEmptyProperty = Object.keys(this.unitData)
        .filter(key => !excludedProperties.includes(key))
        .some(key => !this.unitData[key]);

      return hasEmptyProperty;
    },
    bedTypes() {
      return mapOptions(this.options.bed_types);
    },
    windowLocations() {
      return mapOptions(this.options.window_locations);
    },
    electricityTypes() {
      return mapOptions(this.options.electricity_types);
    },
  },

  watch: {
    editData() {
      if (this.modalType === 'edit') {
        const {
          name,
          floor,
          window_location,
          bed_type,
          bed_size,
          electricity_type,
          electricity_power,
          description,
        } = this.editData;
  
        const [bedLength = '', bedWidth = ''] = (bed_size || '').split('x');
        this.unitData = {
          name,
          floor,
          window_location,
          bed_type,
          bed_length: bedLength,
          bed_width: bedWidth,
          electricity_type,
          electricity_power,
          description,
        };
  
        const fieldsToCheck = [
          { optionFieldName: 'window_locations', dataField: 'window_location' },
          { optionFieldName: 'bed_types', dataField: 'bed_type' },
          {
            optionFieldName: 'electricity_types',
            dataField: 'electricity_type',
          },
        ];
  
        fieldsToCheck.forEach(({ optionFieldName, dataField }) => {
          if (
            !this.options[optionFieldName].includes(this.unitData[dataField])
          ) {
            this.unitData[dataField] = '';
          }
        });
      } else {
        Object.keys(this.unitData).forEach(key => {
          this.unitData[key] = '';
        })
      }

      this.updateUnitDataFromGeneralFacilities();
    },
  },

  mounted() {
    this.updateUnitDataFromGeneralFacilities();
  },

  methods: {
    updateUnitDataFromGeneralFacilities() {
      const [roomLength = '', roomWidth = ''] = (this.generalFacilities?.room_area || '').split('x');
      this.unitData.room_length = roomLength;
      this.unitData.room_width = roomWidth;
    },

    handleField(field) {
      const fieldRegexMap = {
        name: /^[a-zA-Z0-9 ]*$/,
        floor: /^[0-9]*$/,
        bed_length: /^[1-9]\d*\.?\d*$/,
        bed_width: /^[1-9]\d*\.?\d*$/,
        electricity_power: /^[1-9]\d*\.?\d*$/,
      };

      if (!fieldRegexMap[field].test(this.unitData[field])) {
        this.unitData[field] = this.unitData[field].slice(0, -1);
      }
    },

    handleShowModal() {
      this.$emit('on-close-add-edit-modal');
    },

    handleSaveButton() {
      if (!this.isSubmitButtonDisabled) {
        if (this.modalType === 'add') {
          this.addRoomUnitListing();
        } else {
          this.editRoomUnitListing();
        }
      }
    },

    /* eslint-disable  */
    getRoomUnitListingPayload() {
      const {
        room_length,
        room_width,
        bed_length,
        bed_width,
        ...rest
      } = this.unitData;

      const room_size = `${room_length}x${room_width}`;
      const bed_size = `${bed_length}x${bed_width}`;

      return {
        ...rest,
        room_size: room_size,
        bed_size: bed_size,
      };
    },
    /* eslint-enable  */

    async editRoomUnitListing() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.editRoomUnitListing({
          propertyId: this.propertyId,
          listingId: this.listingId,
          unitId: this.unitId,
          data: this.getRoomUnitListingPayload(),
        });
        this.handleShowModal();
        this.$toast.show('Perubahan berhasil disimpan');
        window.location.reload();
      } catch (error) {
        result.hasError = true;
        this.$toast.show(error.response?.data.error);
      }

      this.loadingButton = false;
      return result;
    },

    async addRoomUnitListing() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.postRoomUnitListing({
          propertyId: this.propertyId,
          listingId: this.listingId,
          data: this.getRoomUnitListingPayload(),
        });
        this.handleShowModal();
        this.$toast.show('Kamar berhasil ditambah.');
        window.location.reload();
      } catch (error) {
        result.hasError = true;
        this.$toast.show(error.response?.data.error);
      }
      this.loadingButton = false;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped src="./UnitAddEditModal.scss"></style>

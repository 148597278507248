<template>
  <div class="additional-delete-modal">
    <bg-modal
      :value="showModal"
      :title="modalTitle"
      :description="modalDescription"
      :close-on-click-backdrop="false"
      desktop-size="md"
      @close="handleModal"
    >
      <template #footer>
        <div class="additional-delete-modal__modal-footer">
          <bg-button
            class="additional-delete-modal__modal-footer-btn"
            variant="tertiary"
            @click="handleModal"
            >Batal</bg-button
          >
          <bg-button
            variant="primary"
            @click="handleConfirmDelete"
            :loading="loadingButton"
            >Hapus</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';

export default {
  name: 'AdditionalDeleteModal',

  components: {
    BgModal,
    BgButton,
  },

  data() {
    return {
      isShowModal: false,
      loadingButton: false,
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => {},
    },
    propertyId: {
      type: [String, Number],
      default: '',
    },
    listingId: {
      type: [String, Number],
      default: '',
    },
  },

  computed: {
    feeName() {
      return this.modalData?.name || '';
    },
    feeId() {
      return this.modalData?.id;
    },
    modalTitle() {
      return `Yakin menghapus ${this.feeName}?`;
    },
    modalDescription() {
      return `Informasi mengenai ${this.feeName} ini akan dihapus dan tidak dapat dikembalikan.`;
    },
  },

  methods: {
    handleModal() {
      this.$emit('on-close-delete-modal');
    },
    async handleConfirmDelete() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.deleteAdditionalFee({
          propertyId: this.propertyId,
          listingId: this.listingId,
          feeId: this.feeId,
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }

      if (!result.hasError) {
        this.$toast.show('Biaya dan layanan berhasil dihapus.');
        this.handleModal();
        window.location.reload();
      }
      this.loadingButton = false;
      return result;
    },
  },

  watch: {
    showModal(isOpen) {
      this.isShowModal = isOpen;
    },
  },
};
</script>

<style lang="scss" scoped src="./AdditionalDeleteModal.scss"></style>

<template>
  <div class="multiple-input">
    <button class="multiple-input-box">
      <div
        class="multiple-input-box__item"
        v-for="(item,i) in inputItems"
        :key="i"
      >
        <bg-text size="body-2">{{ item.name }}</bg-text>
        <span
          class="multiple-input-box__item-close"
          @click="handleDeleteItem(i)">
          <bg-icon
            name="close"
            class="multiple-input__item-close"
            size="sm"
          />
        </span>
      </div>
      <bg-search-bar
        v-model="inputValue"
        :suggestions="filteredSuggestions"
        :show-search-icon="false"
        :show-clear-text-icon="false"
        class="multiple-input-box__field"
        @click-suggestion="handleEnterInput"
      />
    </button>
  </div>
</template>

<script>
import { BgText, BgIcon, BgSearchBar } from 'bangul-vue';

export default {
  name: 'MultipleInput',

  components: {
    BgText,
    BgIcon,
    BgSearchBar,
  },

  data() {
    return {
      inputItems: [],
      inputValue: '',
      suggestionList: [],
    }
  },

  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  created() {
    this.suggestionList = this.$props.suggestions;
    if (this.$props.value.length) {
      this.inputItems = this.$props.value.map(
        item => (
          { ...item, 
            isDisable: false,
            label: item.name }
          )
        )
      this.inputItems?.forEach(item => {
        this.handleDisabled(item);
      });
      this.inputItems = [...new Set(this.inputItems)];
    }
  },

  computed: {
    filteredSuggestions() {
      return this.suggestionList.filter(
        suggestion => suggestion.label.toLowerCase().includes(
          this.inputValue.toLowerCase()
        )
      );
    }
  },

  methods: {
    handleDisabled(value) {
      const index = this.suggestionList.findIndex(
        (suggestion => suggestion.name === value.name)
      );
      if (!value?.isDisable) {
        this.inputItems.push(value);
      };
      if (this.suggestionList[index]) {
        this.suggestionList[index].isDisable = true;
      }
    },
    handleEnterInput(value) {
      this.handleDisabled(value);
      this.inputValue = '';
      this.$emit('input', this.inputItems);
    },
    handleDeleteItem(index) {
      const deletedLabel = this.inputItems[index];
      const indexDelete = this.suggestionList.findIndex(
        (suggestion => suggestion.id === deletedLabel.id)
      );
      this.inputItems.splice(this.inputItems.findIndex(item =>
          item.id === deletedLabel.id), 1);
      if (this.suggestionList[indexDelete]) {
        this.suggestionList[indexDelete].isDisable = false;
      }
      this.$emit('input', this.inputItems);
    },
    
  }
};
</script>

<style lang="scss" scoped src="./MultipleInput.scss"></style>

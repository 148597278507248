<template>
  <div class="unit-delete-modal">
    <bg-modal
      :value="showModal"
      title="Yakin menghapus kamar?"
      description="Masukkan alasan di kolom dan klik 'Hapus' untuk konfirmasi."
      :close-on-click-backdrop="false"
      desktop-size="md"
      @close="closeModal"
    >
      <ValidationProvider name="Alasan" rules="min:5" v-slot="{ errors }">
        <bg-field
          :error="errors.length > 0"
          :message="
            errors.length > 0 ? 'Alasan harus memiliki minimal 5 karakter.' : ''
          "
        >
          <bg-input
            v-model="deletionReason"
            placeholder="Tulis alasan menghapus kamar"
            class="bg-u-mt-xs"
          />
        </bg-field>
      </ValidationProvider>

      <template #footer>
        <div class="unit-delete-modal__modal-footer">
          <bg-button
            class="unit-delete-modal__modal-footer-btn"
            variant="tertiary"
            @click="closeModal"
          >
            Batal
          </bg-button>

          <bg-button
            :disabled="deletionReason.length < 5"
            variant="primary"
            @click="handleConfirmDelete"
            :loading="loadingButton"
          >
            Hapus
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { BgModal, BgField, BgInput, BgButton } from 'bangul-vue';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';

export default {
  name: 'UnitDeleteModal',

  components: {
    BgModal,
    BgField,
    BgInput,
    BgButton,
  },

  data() {
    return {
      isShowDeleteModal: false,
      loadingButton: false,
      deletionReason: '',
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: [String, Number],
      default: '',
    },
    listingId: {
      type: [String, Number],
      default: '',
    },
    unitId: {
      type: [String, Number],
      default: '',
    },
  },

  methods: {
    closeModal() {
      this.deletionReason = '';
      this.$emit('on-close-delete-modal');
    },
    async handleConfirmDelete() {
      this.loadingButton = true;

      try {
        const response = await formsPropertyInformationApi
          .deleteRoomUnitListing({
            propertyId: this.propertyId,
            listingId: this.listingId,
            unitId: this.unitId,
            reason: this.deletionReason,
          });

        if (response) {
          this.closeModal();
          this.$toast.show('Kamar berhasil dihapus.');
          window.location.reload();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          this.$emit('on-failure-delete', {
            text: error.response.data?.error,
          });
        } else {
          this.$toast.show(
            error?.response?.data?.error ||
              'Terjadi kesalahan silahkan coba lagi.'
          );
        }
      } finally {
        this.loadingButton = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./UnitDeleteModal.scss"></style>

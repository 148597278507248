var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-property-additional-price",on:{"!click":function($event){return _vm.handleToggleAction(false)}}},[(_vm.loadingFetchData)?_c('bg-skeleton',{attrs:{"width":"40%","height":40,"data-testid":"form-property-additional-price-loader"}}):[_c('div',{staticClass:"form-property-additional-price__header-section"},[_c('bg-text',{attrs:{"size":"heading-2"}},[_vm._v(_vm._s(_vm.xKosListingData.name))])],1),_c('div',{staticClass:"form-property-additional-price__subtitle"},[_c('bg-text',{attrs:{"size":"heading-4"}},[_vm._v("Biaya dan Layanan Tambahan")]),_c('bg-button',{attrs:{"variant":"primary","data-testid":"form-property-additional-price-add-button"},on:{"click":function($event){return _vm.handleShowAddEditModal(true)}}},[_vm._v("+ Tambah Biaya & Layanan")])],1),_c('table',{staticClass:"ss-table"},[_c('thead',[_c('tr',{staticClass:"form-property-additional-price__table-head"},[_c('th',{staticClass:"form-property-additional-price__table-col-lg"},[_vm._v(" Jenis Biaya ")]),_c('th',{staticClass:"form-property-additional-price__table-col-md"},[_vm._v(" Besar Biaya ")]),_c('th',{staticClass:"form-property-additional-price__table-col-md"},[_vm._v("Satuan")]),_c('th',{staticClass:"form-property-additional-price__table-col-sm"},[_vm._v("Action")])])]),_c('tbody',{attrs:{"data-testid":"form-property-additional-price-table-body"}},_vm._l((_vm.fixedFeeData),function(unit,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(unit.label))]),_c('td',[_vm._v(_vm._s(_vm.generatePriceFormat(unit)))]),_c('td',[_vm._v(_vm._s(_vm.generateDuration(unit.duration_unit, unit.duration)))]),_c('td',{staticClass:"form-property-additional-price__table-col-sm"},[_c('bg-icon',{staticClass:"form-property-additional-price__table-action--button",attrs:{"name":"more-vertical","data-testid":"action-trigger"},nativeOn:{"click":function($event){return _vm.handleToggleAction(i, 'type', unit)}}}),(
                _vm.isType &&
                _vm.isShowCardAction.active &&
                i === _vm.isShowCardAction.actionId
              )?_c('bg-card',{staticClass:"form-property-additional-price__table-action"},_vm._l((_vm.actions),function(action,i){return _c('bg-list-item',{key:i},[_c('div',{staticClass:"form-property-additional-price__table-action--row",on:{"click":function($event){return action.actionClick(unit)}}},[_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(action.title))])],1)])}),1):_vm._e()],1)])}),0)]),_c('table',{staticClass:"ss-table"},[_c('thead',[_c('tr',{staticClass:"form-property-additional-price__table-head"},[_c('th',{staticClass:"form-property-additional-price__table-col-lg"},[_vm._v(" Biaya Lainnya ")]),_c('th',{staticClass:"form-property-additional-price__table-col-lg"},[_vm._v(" Besar Biaya ")]),_c('th',{staticClass:"form-property-additional-price__table-col-sm"},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.paginationData),function(unit,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(unit.name))]),_c('td',[_vm._v(_vm._s(_vm._f("rupiah")(unit.amount)))]),_c('td',{staticClass:"form-property-additional-price__table-col-sm"},[_c('bg-icon',{staticClass:"form-property-additional-price__table-action--button",attrs:{"name":"more-vertical"},nativeOn:{"click":function($event){return _vm.handleToggleAction(i, 'other', unit)}}}),(
                _vm.isOther &&
                _vm.isShowCardAction.active &&
                i === _vm.isShowCardAction.actionId
              )?_c('bg-card',{staticClass:"form-property-additional-price__table-action"},_vm._l((_vm.actions),function(action,i){return _c('bg-list-item',{key:i},[_c('div',{staticClass:"form-property-additional-price__table-action--row",on:{"click":function($event){return action.actionClick(unit)}}},[_c('bg-text',{attrs:{"size":"input-lg"}},[_vm._v(_vm._s(action.title))])],1)])}),1):_vm._e()],1)])}),0)]),_c('div',{staticClass:"form-property-additional-price__footer-section"},[(_vm.additionalData.length > _vm.pagination.limit)?_c('bg-pagination',{attrs:{"page-total":_vm.pagination.total},on:{"click":_vm.handlePagination},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1),_c('additional-add-edit-modal',{attrs:{"show-modal":_vm.isShowAddEditModal,"modal-type":_vm.addEditModalType,"edit-data":_vm.modalData,"listing-id":_vm.listingId,"property-id":_vm.propertyId,"data-testid":"additional-add-edit-modal"},on:{"on-close-add-edit-modal":_vm.handleShowAddEditModal}}),_c('additional-delete-modal',{attrs:{"show-modal":_vm.isShowDeleteModal,"modal-data":_vm.modalData,"listing-id":_vm.listingId,"property-id":_vm.propertyId,"data-testid":"additional-delete-modal"},on:{"on-close-delete-modal":_vm.handleShowDeleteModal}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
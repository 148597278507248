<template>
  <div class="form-property-information">
    <bg-skeleton v-if="loadingFetchData" width="40%" :height="40" />
    <template v-else>
      <div class="form-property-information__header-section">
        <bg-text size="heading-2">Data Properti</bg-text>
      </div>
      <div class="form-property-information__form-section">
        <div class="form-property-keeper">
          <div class="form-property-information__keeper-header-section">
            <bg-text size="heading-4">Informasi Penjaga</bg-text>
            <bg-button
              v-if="keeperData.length < 5"
              variant="primary"
              @click="handleAddList"
              >+ Tambah Informasi Penjaga</bg-button
            >
          </div>
          <bg-grid>
            <bg-grid-item class="form-property-information__keeper-label">
              <bg-text size="title-5">Nama Penjaga Kos</bg-text>
            </bg-grid-item>
            <bg-grid-item class="form-property-information__keeper-label">
              <bg-text size="title-5">No. HP Penjaga Kos</bg-text>
            </bg-grid-item>
          </bg-grid>
          <bg-grid v-for="(item, i) in keeperData" :key="i">
            <bg-grid-item>
              <bg-input v-model="item.name" :error="!item.name" />
              <bg-text
                v-if="!item.name"
                size="body-4"
                class="form-property-information__error"
              >
                Masukkan nama penjaga kos terlebih dahulu.
              </bg-text>
            </bg-grid-item>
            <bg-grid-item>
              <div class="form-property-information__keeper-flex-row">
                <bg-input
                  class="form-property-information__keeper-number"
                  v-model="item.phone_number"
                  type="number"
                  :error="!item.phone_number"
                />
                <span
                  class="form-property-information__keeper-delete"
                  @click="handleDeleteList(i, item)"
                >
                  <bg-icon name="delete" />
                </span>
              </div>
              <bg-text
                v-if="!item.phone_number"
                size="body-4"
                class="form-property-information__error"
              >
                Masukkan nomor HP penjaga kos terlebih dahulu.
              </bg-text>
            </bg-grid-item>
          </bg-grid>
        </div>
        <div class="form-property-information__category">
          <bg-text
            size="heading-4"
            class="form-property-information__category-title"
            >Jadwal Survei yang Tersedia</bg-text
          >
          <div class="form-property-information__flex-row">
            <bg-field
              label="Jam Mulai Survei"
              :message="inputMessage.surveyFrom"
              :error="!!inputMessage.surveyFrom"
            >
              <bg-input
                v-model="detailData.survey_time.survey_time_from"
                type="time"
              />
            </bg-field>
            <bg-text
              size="title-5"
              class="form-property-information__middle-text"
              >s/d</bg-text
            >
            <bg-field
              label="Jam Selesai Survei"
              :message="inputMessage.surveyTo"
              :error="!!inputMessage.surveyTo"
            >
              <bg-input
                v-model="detailData.survey_time.survey_time_to"
                type="time"
              />
            </bg-field>
          </div>
        </div>
        <div class="form-property-strategic-location">
          <div class="form-property-strategic-location__header">
            <bg-text
              size="heading-4"
              class="form-property-strategic-location-title"
            >
              Lokasi Strategis
            </bg-text>
            <bg-button
              variant="secondary"
              :disabled="strategicLocation.length >= 10"
              @click="strategicLocation.push({ location: '' })"
            >
              + Tambah Lokasi Strategis
            </bg-button>
          </div>
          <div
            v-for="(item, index) in strategicLocation"
            :key="index"
          >
            <bg-field
              :error="item.location.length > 255"
              :message="
                item.location.length > 255 ?
                  'Panjang karakter maksimal adalah 255 karakter.' : ''
              "
            >
              <div class="form-property-strategic-location__input">
                <bg-input
                  v-model="item.location"
                  placeholder="Lokasi Strategis"
                />

                <bg-button-icon
                  icon="delete"
                  variant="tertiary-naked"
                  :disabled="strategicLocation.length === 1"
                  @click="handleDeleteStrategicLocation('confirmation', index)"
                />
              </div>
            </bg-field>
          </div>
        </div>
        <div class="form-property-information__category">
          <bg-text
            size="heading-4"
            class="form-property-information__category-title"
            >Fasilitas Bersama Kos</bg-text
          >
          <multiple-input
            v-if="!loadingFetchTagList"
            :suggestions="sharedTags"
            @input="handleSharedInput"
            :value="detailData.shared_facilities"
          />
        </div>
        <div class="form-property-information__category">
          <bg-grid>
            <bg-grid-item>
              <bg-field label="Provider Wifi">
                <bg-input
                  v-model="detailData.wifi_provider"
                  :disabled="disableWifiField"
                />
              </bg-field>
            </bg-grid-item>
            <bg-grid-item>
              <bg-field label="Kecepatan Wifi">
                <bg-input
                  v-model="detailData.wifi_speed"
                  :disabled="disableWifiField"
                />
              </bg-field>
            </bg-grid-item>
          </bg-grid>
        </div>

        <div class="form-property-information__category">
          <bg-text
            size="heading-4"
            class="form-property-information__category-title"
            >Fasilitas Parkir</bg-text
          >
          <bg-grid>
            <bg-grid-item :col="6">
              <bg-grid
                v-for="(item, i) in detailData.parking_facilities"
                :key="i"
                vAlign="center"
              >
                <bg-grid-item :col="6">
                  <bg-checkbox
                    :id="item.parking_type"
                    :label="item.parking_type"
                    v-model="parkingValue"
                    :value="parkingType[item.parking_type]"
                  />
                </bg-grid-item>
                <bg-grid-item :col="6">
                  <bg-input
                    v-model="item.total_quota"
                    :disabled="
                      !parkingValue.includes(parkingType[item.parking_type])
                    "
                    :error="
                      parkingValue.includes(parkingType[item.parking_type]) &&
                      !item.total_quota
                    "
                  />
                  <bg-text
                    v-if="
                      parkingValue.includes(parkingType[item.parking_type]) &&
                      !item.total_quota
                    "
                    size="body-4"
                    class="form-property-information__error"
                  >
                    Masukkan kapasitas {{ item.parking_type }} terlebih dahulu.
                  </bg-text>
                </bg-grid-item>
              </bg-grid>
            </bg-grid-item>
          </bg-grid>
        </div>

        <div class="form-property-information__category">
          <bg-text
            size="heading-4"
            class="form-property-information__category-title"
            >Peraturan Kos</bg-text
          >
          <multiple-input
            v-if="!loadingFetchTagList"
            :suggestions="rulesTags"
            @input="handleRulesInput"
            :value="detailData.property_rules"
          />
        </div>

        <div class="form-property-information__category">
          <bg-text
            size="heading-4"
            class="form-property-information__category-title"
            >Info Tambahan</bg-text
          >
          <multiple-input
            v-if="!loadingFetchTagList"
            :suggestions="additionalTags"
            @input="handleAdditionalInput"
            :value="filteredAdditionalData"
          />
        </div>
        <div class="form-property-information__category">
          <bg-grid>
            <bg-grid-item>
              <bg-field label="Jarak Kos dari Jalan Utama">
                <bg-input v-model="detailData.mainroad_distance" />
              </bg-field>
            </bg-grid-item>
            <bg-grid-item>
              <bg-field
                label="Jam Buka Portal"
                :message="inputMessage.portalOpen"
                :error="!!inputMessage.portalOpen"
              >
                <bg-input v-model="detailData.portal_open_time" type="time" />
              </bg-field>
            </bg-grid-item>
            <bg-grid-item>
              <bg-field
                label="Jam Tutup Portal"
                :message="inputMessage.portalClose"
                :error="!!inputMessage.portalClose"
              >
                <bg-input v-model="detailData.portal_close_time" type="time" />
              </bg-field>
            </bg-grid-item>
          </bg-grid>
        </div>

        <bg-button
          variant="primary"
          :disabled="isSimpanBtnDisabled"
          @click="handleShowConfirmModal"
        >
          Simpan
        </bg-button>

        <bg-modal
          v-model="showDeleteStrategicLocationModal"
          title="Yakin menghapus informasi lokasi strategis?"
          description="Informasi mengenai lokasi strategis ini akan dihapus dan tidak dapat dikembalikan."
          desktop-size="sm"
          :close-on-click-backdrop="false"
          button-main-text="Hapus"
          button-second-text="Batal"
          @click-main-action="handleDeleteStrategicLocation('delete')"
          @click-second-action="showDeleteStrategicLocationModal = false"
        />

        <bg-modal
          v-model="isShowConfirmModal"
          title="Yakin menyimpan perubahan?"
          description="Perubahan pada data properti yang dilakukan akan diterapkan langsung ke iklan kos."
        >
          <template #footer>
            <div class="form-property-information__modal-footer">
              <bg-button
                class="form-property-information__modal-footer-btn"
                variant="tertiary"
                :loading="loadingButton"
                @click="handleShowConfirmModal"
                >Batal</bg-button
              >
              <bg-button
                variant="primary"
                :loading="loadingButton"
                @click="handleSaveData"
                >Simpan</bg-button
              >
            </div>
          </template>
        </bg-modal>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BgSkeleton,
  BgText,
  BgField,
  BgInput,
  BgGrid,
  BgGridItem,
  BgCheckbox,
  BgIcon,
  BgButton,
  BgButtonIcon,
  BgModal,
} from 'bangul-vue';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';
import propertyDetailApi from '@admin_endpoints/property-detail';
import MultipleInput from '@admin_molecules/MultipleInput';
import { mapMutations } from 'vuex';

export default {
  name: 'FormPropertyInformation',

  components: {
    BgSkeleton,
    BgText,
    BgField,
    BgInput,
    BgGrid,
    BgGridItem,
    BgCheckbox,
    BgButton,
    BgButtonIcon,
    MultipleInput,
    BgIcon,
    BgModal,
  },

  computed: {
    propertyId() {
      return this.$route.params.propertyId;
    },
    sharedTags() {
      const tagsList = this.sharedSuggestion?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
    rulesTags() {
      const tagsList = this.rulesSuggestion?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
    additionalTags() {
      const tagsList = this.additionalSuggestion?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
    sharedCurrent() {
      const tagsList = this.detailData?.shared_facilities?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
    propertyName() {
      return localStorage.getItem('propertyName');
    },
    isSimpanBtnDisabled() {
      const isStrategicLocationValid = this.strategicLocation.filter(
        item => item.location.length > 255
      );

      return !!isStrategicLocationValid.length;
    }
  },

  created() {
    this.handleBreadcrumb();
    this.consumePropertyDetail();
    this.consumeTagList();
  },

  data() {
    return {
      sendKeeperData: [],
      keeperData: [],
      strategicLocation: [
        { location: '' }
      ],
      oldKeeperData: [],
      newKeeperData: [],
      detailData: null,
      loadingFetchData: false,
      loadingFetchTagList: false,
      parkingValue: [],
      parkingType: {
        'Parkir Mobil': 'car',
        'Parkir Motor & Sepeda': 'motorcycle',
        'Parkir Motor': 'motorcycle',
        'Parkir Sepeda': 'bike',
      },
      parkingOptions: [
        {
          parking_type: 'Parkir Mobil',
          total_quota: 0,
        },
        {
          parking_type: 'Parkir Motor',
          total_quota: 0,
        },
        {
          parking_type: 'Parkir Sepeda',
          total_quota: 0,
        },
      ],
      sharedSuggestion: [],
      rulesSuggestion: [],
      additionalSuggestion: [],
      sharedList: [],
      additionalList: [],
      rulesList: [],
      filteredAdditionalData: [],
      isShowConfirmModal: false,
      loadingButton: false,
      disableWifiField: false,
      inputMessage: {
        surveyFrom: '',
        surveyTo: '',
        portalOpen: '',
        portalClose: '',
        keeper: false,
        parking: false,
      },
      showDeleteStrategicLocationModal: false,
      toBeDeletedStrategicLocation: null,
    };
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.propertyName,
          url: `/property-detail/${this.propertyId}/kos`,
        },
      });
    },

    handleAddList() {
      const addition = {
        operation: 'add',
      };
      this.keeperData.push(addition);
      this.sendKeeperData.push(addition);
    },

    handleDeleteList(index, data) {
      this.keeperData.splice(index, 1);
      if (data.id) {
        const deletion = {
          operation: 'delete',
          keeper: {
            id: data.id,
            name: data.name,
            phone_number: data.phone_number,
          },
        };

        this.sendKeeperData.push(deletion);
      } else {
        this.sendKeeperData.splice(index, 1);
      }
    },

    handleUpdateKeeper() {
      let updatedData = this.oldKeeperData.filter(
        item => !(item.operation === 'add' || item.operation === 'deletion')
      );
      let addedData = this.sendKeeperData.filter(
        item => item.operation === 'add'
      );
      const deletedData = this.sendKeeperData.filter(
        item => item.operation === 'delete'
      );
      updatedData = updatedData.map(item => ({
        operation: 'update',
        keeper: {
          id: item.id,
          name: item.name,
          phone_number: item.phone_number,
        },
      }));

      addedData = addedData.map(item => ({
        operation: 'add',
        keeper: {
          name: item.name,
          phone_number: item.phone_number,
        },
      }));

      this.newKeeperData = [...updatedData, ...addedData, ...deletedData];

      this.postPropertyKeeper();
    },

    handleShowConfirmModal() {
      const { survey_time_from, survey_time_to } = this.detailData.survey_time;
      const { portal_open_time, portal_close_time } = this.detailData;
      const emptyCheckedParking = this.detailData.parking_facilities.filter(
        item =>
          this.parkingValue.includes(this.parkingType[item.parking_type]) &&
          !item.total_quota
      );
      const keeperPhoneEmpty = this.keeperData.filter(
        data => data.name && !data.phone_number
      );
      const keeperNameEmpty = this.keeperData.filter(
        data => !data.name && data.phone_number
      );
      let surveyFrom;
      let portalOpen;
      let surveyTo;
      let portalClose;
      let keeper;
      let parking;

      if (emptyCheckedParking.length > 0) {
        parking = true;
      }

      if (keeperPhoneEmpty.length > 0) {
        keeper = true;
      } else if (keeperNameEmpty.length > 0) {
        keeper = true;
      }

      if (survey_time_from) {
        if (!survey_time_to) {
          surveyTo = 'Masukkan jam akhir survei.';
        } else if (survey_time_from > survey_time_to) {
          surveyFrom = 'Jam mulai survei harus kurang dari jam selesai survei.';
        } else if (survey_time_from === survey_time_to) {
          surveyFrom =
            'Jam mulai survei harus berbeda dengan jam selesai survei.';
        }
      } else if (!survey_time_from && survey_time_to) {
        surveyFrom = 'Masukkan jam mulai survei.';
      }

      if (portal_open_time) {
        if (!portal_close_time) {
          portalClose = 'Masukkan jam portal ditutup.';
        } else if (portal_open_time > portal_close_time) {
          portalOpen = 'Jam buka portal harus kurang dari jam tutup portal.';
        } else if (portal_open_time === portal_close_time) {
          portalOpen = 'Jam buka portal harus berbeda dengan jam tutup portal.';
        }
      } else if (!portal_open_time && portal_close_time) {
        portalOpen = 'Masukkan jam portal dibuka.';
      }

      this.inputMessage = {
        surveyFrom,
        surveyTo,
        portalOpen,
        portalClose,
        keeper,
        parking,
      };

      if (
        Object.values(this.inputMessage).every(
          value => !value || value === '' || value === undefined
        )
      ) {
        this.loadingButton = false;
        this.isShowConfirmModal = !this.isShowConfirmModal;
      }
    },

    handleDeleteStrategicLocation(type, index) {
      if (type === 'confirmation') {
        this.toBeDeletedStrategicLocation = index;
        this.showDeleteStrategicLocationModal = true;
      } else if (type === 'delete') {
        this.strategicLocation.splice(this.toBeDeletedStrategicLocation, 1);
        this.toBeDeletedStrategicLocation = null;
        this.showDeleteStrategicLocationModal = false;
      }
    },

    async handleSaveData() {
      this.loadingButton = true;

      const parkingData = this.detailData.parking_facilities.map(item => ({
        type: this.parkingType[item.parking_type],
        quota: item.total_quota ? item.total_quota : 0,
      }));
      const sendData = {
        survey_time: {
          start: this.detailData.survey_time.survey_time_from,
          end: this.detailData.survey_time.survey_time_to,
        },
        shared_facilities: this.sharedList,
        parking_facilities: parkingData,
        rules: this.rulesList,
        additional_infos: this.additionalList,
        wifi_provider: this.detailData.wifi_provider,
        wifi_speed: this.detailData.wifi_speed,
        mainroad_distance: this.detailData.mainroad_distance,
        portal_open_time: this.detailData.portal_open_time,
        portal_close_time: this.detailData.portal_close_time,
      };

      await this.updateStrategicLocation({
        locations: this.strategicLocation
          .filter(item => item.location.length)
          .map(item => item.location)
      });

      await this.handleUpdateKeeper();

      await this.updatePropertyInformation(sendData);

      this.loadingButton = false;
      this.isShowConfirmModal = false;
    },

    handleRulesInput(input) {
      const list = input.map(({ id }) => id);
      this.rulesList = [...new Set(list)];
    },

    handleSharedInput(input) {
      const listId = input.map(({ id }) => id);
      const listName = input.map(({ name }) => name);
      this.sharedList = [...new Set(listId)];
      this.disableWifiField = !listName.includes('WiFi');
    },

    handleAdditionalInput(input) {
      const list = input.map(({ id }) => id);
      this.additionalList = [...new Set(list)];
    },

    async postPropertyKeeper() {
      this.loadingFetchData = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.createBulkPropertyKeeper({
          propertyId: this.propertyId,
          data: this.newKeeperData,
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }
      this.loadingFetchData = false;
      return result;
    },

    async updateStrategicLocation(data) {
      try {
        const response = await formsPropertyInformationApi.updateStrategicLocation({
          propertyId: this.propertyId,
          data,
        });

        if (response) {
          this.$toast.show('Perubahan berhasil disimpan');
        }
      } catch (error) {
        this.$toast.show(error.response.data.error || 'Terjadi kesalahan');
      }
    },

    async updatePropertyInformation(data) {
      this.loadingFetchData = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.updatePropertyInformation({
          propertyId: this.propertyId,
          data,
        });
        this.$toast.show('Perubahan berhasil disimpan');
        this.handleShowConfirmModal();
        window.location.reload();
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }
      this.loadingFetchData = false;
      return result;
    },

    async fetchPropertyDetail() {
      let result = {};
      try {
        result = await propertyDetailApi.getKosRulesAndInfos(this.propertyId);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(result);
        console.error(error);
      }
      return result;
    },

    async consumePropertyDetail() {
      this.loadingFetchData = true;
      const response = await this.fetchPropertyDetail();

      if (!response.hasError) {
        const { strategic_location } = response?.data?.data || [];

        this.detailData = response?.data?.data;
        this.keeperData = response?.data?.data?.property_keepers;
        this.oldKeeperData = response?.data?.data?.property_keepers;
        if (strategic_location.length) {
          this.strategicLocation = response.data.data.strategic_location.map(
            item => ({ location: item })
          );
        }
        this.filteredAdditionalData = this.detailData.additional_infos.filter(
          info => info.id
        );

        this.handleAdditionalInput(this.filteredAdditionalData);
        this.handleRulesInput(this.detailData.property_rules);
        this.handleSharedInput(this.detailData.shared_facilities);

        if (this.detailData.parking_facilities.length === 0) {
          this.detailData.parking_facilities = this.parkingOptions;
        }
      }

      this.loadingFetchData = false;
    },

    async fetchTagList(type) {
      let result = {};
      try {
        result = await formsPropertyInformationApi.getPropertyTagList(type);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(result);
        console.error(error);
      }
      return result;
    },

    async consumeTagList() {
      this.loadingFetchTagList = true;

      const responseShared = await this.fetchTagList('shared');
      const responseAdditional = await this.fetchTagList('outside');
      const responseRules = await this.fetchTagList('kost_rule');

      if (responseShared.status) {
        this.sharedSuggestion = responseShared?.data?.tags;
      }

      if (responseAdditional.status) {
        this.additionalSuggestion = responseAdditional?.data?.tags;
      }

      if (responseRules.status) {
        this.rulesSuggestion = responseRules?.data?.tags;
      }
      this.loadingFetchTagList = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyInformation.scss"></style>
